import bilAPI from '../api';

export const getParticipatedSchools = (
  examCollectionUuid,
  { position_filter },
) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/get_participated_schools/${examCollectionUuid}`, {
          params: {
            position_filter,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
